<template>
  <section class="yellow-light-green flex flex-col items-center justify-center py-16">
    <UiStripesArticles
      background="yellow-light-green"
      :show-all="false"
      :range="[3, 6]"
    />
    <UiStripesArticles
      background="yellow-light-green"
      :show-all="false"
      :range="[6, 8]"
    />
    <UiStripesArticles
      background="yellow-light-green"
      :show-all="true"
      :range="[8, 12]"
    />
  </section>
</template>
